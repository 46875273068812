<template>
  <header class="main-header">
    <a
      class="sidebar-toggle feather-menu"
      @click="toggleSidebar"
    >
      <span class="sr-only">Przełącz nawigację</span>
    </a>
    <a
      href="/"
      class="logo"
    >
      <span class="logo-lg">
        <img
          src="../../../public/static/assets/img/logo.svg"
          alt="Logo"
        >
        <!--<span><small class="version-text">1.0</small></span>-->
      </span>
    </a>
    <nav class="navbar navbar-static-top">
      <bread-crumb/>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav navbar-right">
          <li
            class="main-header--notification"
          >
            <a v-if="incompleteTasks.length !== 0" href="" @click.prevent="redirectToIncompleteTasks"
               title="'Niedokończone zlecenia'">
              <i class="fa fa-bell notification-danger"></i>
              <span class="main-header--notification-counter">{{ incompleteTasks.length }}</span>
            </a>
            <!-- <ul
              :class="{'user-menu-dropdown-closed': !isNotificationOpened}"
              class="main-header user-menu-dropdown list-unstyled sidebar--menu-list"
            >
              <li v-for="(notification, id) in notifications" :key="id" class="menu-element">
                <a href="" @click.prevent=""><span :key="id" class="menu-element">{{ notification.message }}</span></a>
              </li>
            </ul> -->
          </li>
          <li
            :class="{'main-header--user-name': true, 'dropdown-reverted': isPasswordOld, 'dropdown-not-reverted': !isPasswordOld}"
          >
            <a
              :class="{'no-rotation': isPasswordOld}"
              @click.prevent="() => isPasswordOld && redirectToMyProfile()"
            >{{ dropdownText || $t('menu.userGreeting', {name: fullname}) }}</a>
            <ul
              :class="{}"
              class="main-header-right-menu list-unstyled sidebar--menu-list menu-dropdown-container"
            >
              <li class="menu-element">
                <router-link
                  :to="{name: 'admin_user_details', params: {uuid: userUuid}}"
                ><i class="fa fa-users"/><span class="menu-element">{{ $t('menu.yourProfile') }}</span></router-link>
              </li>
              <li
                v-if="$auth.isAllowed({service: 'core', action: 'get_user_presences'})"
                class="menu-element"
              >
                <router-link :to="{name: 'admin_user_presences', params: {uuid: userUuid}}">
                  <i class="fa feather-clock"/><span class="">{{ $t('menu.presence') }}</span>
                </router-link>
              </li>
              <hr class="menu-separator">
              <li class="menu-element">
                <router-link :event="''" @click.native.prevent="securityLogout()" to="">
                  <i class="fa fa-sign-out"/><span class="">{{ $t('menu.logout') }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import BreadCrumb from '../share/content/content-header/BreadCrumb'
import Logout from '../share/mixins/Logout'
import WebStorage from '../share/WebStorage'
import moment from 'moment'
import {mapActions, mapState} from 'vuex'
import api from '../../api'

export default {
  components: {
    BreadCrumb
  },
  mixins: [
    Logout
  ],
  data () {
    return {
      isUserMenuOpened: false,
      isNotificationOpened: false,
      sidebarOpened: true,
      notifications: [
        {id: 1, message: 'Nowe zlecenie nr:1456', link: '/'},
        {id: 2, message: 'Zlecenie nr:1234 anulowane', link: '/'},
        {id: 3, message: 'Zlecenie nr: 1345 zakończone', link: '/'},
        {id: 4, message: 'Przypomnienie o realizacji zlecenia', link: '/'}
      ],
      passwordExpirationDate: null,
      isPasswordOld: false,
      dropdownText: ''
    }
  },
  computed: {
    ...mapState({
      incompleteTasks: state => state.incompleteTasks.tasks
    }),
    fullname () {
      return WebStorage.getUser().forename
    },
    userUuid () {
      return WebStorage.getUser().uuid
    }
  },
  async beforeMount () {
    await this.callIncompleteTasksMethod()
  },
  mounted () {
    this.getPasswordExpirationDate()
    this.isPasswordNearlyExpired()
  },
  methods: {
    ...mapActions({
      getIncompleteTasks: 'getIncompleteTasks'
    }),
    toggleSidebar () {
      let body = document.getElementsByTagName('body')[0]
      if (this.sidebarOpened) {
        body.classList.add('sidebar-collapse')
        body.classList.remove('sidebar-open')
        this.sidebarOpened = !this.sidebarOpened
      } else {
        body.classList.remove('sidebar-collapse')
        body.classList.add('sidebar-open')
        this.sidebarOpened = !this.sidebarOpened
      }
    },
    redirectToMyProfile () {
      this.$router.push(`/administracja-systemem/uzytkownicy/${this.$store.state.base.user.uuid}`)
    },
    redirectToIncompleteTasks () {
      this.$router.push({name: 'incomplete-task'})
    },
    setPaswordNotif () {
      let timeToReset = Math.ceil(((this.passwordExpirationDate - Date.now() / 1000) / 86400) + 1)
      this.isPasswordOld = !this.isPasswordOld
      this.isPasswordOld === true ? this.dropdownText = this.$t('menu.passwordTooOld', {date: `${timeToReset > 1 ? timeToReset + ' dni.' : timeToReset + ' dzień.'}`}) : this.dropdownText = this.$t('menu.userGreeting', {name: this.fullname})
    },
    isPasswordNearlyExpired () {
      var now = Date.now()
      if (this.passwordExpirationDate - now / 1000 < (86400 * 5)) {
        this.passwordExpirationNotif()
      } else {
        this.isPasswordOld = false
        this.dropdownText = this.$t('menu.userGreeting', {name: this.fullname})
      }
    },
    passwordExpirationNotif () {
      this.dropdownText = this.$t('menu.userGreeting', {name: this.fullname})
      setInterval(() => {
        this.setPaswordNotif()
        setTimeout(() => {
          this.setPaswordNotif()
        }, 5000)
      }, 10000)
    },

    getPasswordExpirationDate () {
      this.passwordExpirationDate = moment(this.$store.state.base.user.passwordExpirationDate.date).unix()
    },
    callIncompleteTasksMethod () {
      let contractors = {}
      Object.entries(WebStorage.getSecurityActions()).filter(([contractorKey, contractorValue], index) => contractorValue.length !== 0 && (contractorKey === 'intgen' || contractorKey === 'intbls' || contractorKey === 'intvig')).forEach(([contractorKey, contractorValue], index) => contractors[contractorKey] = contractorValue)
      this.getIncompleteTasks({services: contractors})
    },
    securityLogout () {
      api.request('core', 'post', '/security/logout', [''])
        .then((response) => {
          console.log('LOGOUT SUCCESS')
          console.log(response.data())
          this.logout()
          this.$router.push({ name: 'security_login', query: { redirect: this.$route.fullPath }, params: { notification: 'security-logout' } })
        })
        .catch(() => {
          console.log('LOGOUT ERROR')
          this.logout()
          this.$router.push({ name: 'security_login', query: { redirect: this.$route.fullPath }, params: { notification: 'security-logout' } })
        })
      this.logout()
      this.$router.push({ name: 'security_login', query: { redirect: this.$route.fullPath }, params: { notification: 'security-logout' } })
    }
  }
}
</script>
<style>
.notification-danger {
  color: #f65c43
}

.main-header-right-menu {
  position: fixed;
  top: 48px;
  z-index: 1030;
  background: #1f2431;
}

.menu-dropdown-container {
  right: 0;
  width: 160%;
}

.menu-dropdown-container > li {
  width: 100%;
}

.menu-dropdown-container > li a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
}

.menu-dropdown-container > li a > * {
  line-height: 1.42857143;
}

.main-header--user-name:not(:hover) > .menu-dropdown-container {
  height: 0;
}
.main-header--user-name:not(:hover) > .menu-dropdown-container > * {
    display: none;
}
</style>
