import WebStorage from '../WebStorage'

export default {
  methods: {
    logout () {
      console.log('LOGOUT')
      this.$store.commit('SET_USER', null)
      this.$store.commit('SET_TOKEN', '')
      this.$store.commit('SET_REFRESHTOKEN', '')
      this.$store.commit('SET_SECURITY_ACTIONS', {})
      WebStorage.removeToken()
      WebStorage.removeRefreshToken()
      WebStorage.removeUser()
      WebStorage.removeIsCurrentSession()
      WebStorage.removeSecurityActions()
      WebStorage.removeContractorApps()
    }
  }
}
